
let from;

export default {
    openUrl(url) {
        this.initFrom();
        if (from === 'android') {
            window.androidJs.openUrl(url)
        }
        else if(from === 'h5'){
            window.open(url,'')
        }
        else  {
            window.webkit.messageHandlers.openUrl.postMessage({url: url})
        }

    },
    startIntent(url){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.startIntent(url)
        }
        else if(from === 'h5'){
            window.parent.postMessage({operation: "startIntent", param: {message: '请自行打开微信，进行绑定'}}, '*')
        }
        else  {
            window.webkit.messageHandlers.startIntent.postMessage({url: url})
        }
    },
    close(){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.close()
        }
        else if(from === 'h5'){
            window.parent.postMessage({operation: "closeMenu", param: {}}, '*')
        }
        else  {
            window.webkit.messageHandlers.close.postMessage(null)
        }
    },
    logout(){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.logout()
        }
        else if(from === 'h5'){
            window.parent.postMessage({operation: "logout", param: {}}, '*')
        }
        else  {
            window.webkit.messageHandlers.logout.postMessage(null)
        }
    },
    copy(content){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.copy(content)
        }else if(from === 'h5'){
            window.parent.postMessage({operation: "copy", param: {content: content}}, '*')
        }
        else  {
            window.webkit.messageHandlers.copy.postMessage({content: content})
        }
    },
    setJs(jsz){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.setJs(jsz)
        }
        else if(from === 'h5'){
            window.parent.postMessage({operation: "gameSpeed", param: {speed: jsz}}, '*')
        }
        else  {
            //window.webkit.messageHandlers.setJs.postMessage({jsz: jsz})
        }
    },
    checkQiliApp() {
        this.initFrom();
        if (from === 'android') {
            return window.androidJs.checkQiliApp();
        }
        else  {
            //window.webkit.messageHandlers.setJs.postMessage({jsz: jsz})
        }
    },
    goQiliApp() {
        this.initFrom();
        if (from === 'android') {
            window.androidJs.goQiliApp();
        }
        else  {
            //window.webkit.messageHandlers.setJs.postMessage({jsz: jsz})
        }
    },
    initFrom() {
        if (!from) {
            from = localStorage.getItem('from')
        }
    }


}