<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">七里APP</span>
        </div>

        <div class="main">
            <div class="list-item"  @click="goQiliApp">
                <img src="../../img/menu/qili-logo.png" style="width: 50px;height: 50px;border-radius: 10px;">
                <div class="kf-info">
                    <div style="font-weight: bold;">
                        七里游戏APP
                    </div>
                    <span style="font-size: 12px;color: #999999;text-align: left">超多类型，超多福利，超多游戏，欢迎下载体验。</span>
                </div>
            </div>

            <div v-if="install === '1'" class="button-view">
                <div>设备已安装七里游戏APP</div>

                <van-button type="info"  native-type="submit" :loading="showLoading" round
                            style="height: 33px;font-weight: bold;width: 160px;font-size: 12px;margin-top: 15px;"
                            loading-text="打开APP中"  @click="goQiliApp"
                            color="#0f78e8">
                    打开七里APP
                </van-button>
            </div>

            <div v-else class="button-view">
                <div>设备还未安装七里游戏APP</div>

                <van-button type="info"  native-type="submit" :loading="showLoading" round
                            style="height: 33px;font-weight: bold;width: 160px;font-size: 12px;margin-top: 15px;"
                            loading-text="打开下载地址"  @click="goQiliApp"
                            color="#0f78e8">
                    去下载七里APP
                </van-button>
            </div>


        </div>

    </div>
</template>

<script>
    import Toast from "vant/lib/toast";
    import notice from '@/api/notice'

    export default {
        name: "shj-qili-app",
        components: {
        },
        data() {
            return {
                showLoading: false,
                from: '',
                install: '0',
            }
        },
        mounted() {
            this.install = notice.checkQiliApp();
        },
        methods: {
            goQiliApp() {
                notice.goQiliApp();
                this.setLoading();
            },
            setLoading() {
                this.showLoading = true;
                setTimeout(() => {
                    this.showLoading = false;
                }, 1500)
            },
            back() {
                this.$router.back()
            },
        }
    }
</script>

<style scoped>

    .list-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        width: 100%;
        height: 70px;
        padding: 0;
        box-sizing: border-box;
        font-size: 13px;
        background: #ffffff;
        border-radius: 5px;
    }
    .kf-info {
        font-size: 15px;
        color: #333333;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 15px;
    }
    .button-view {
        width: 100%;
        font-size: 12px;
        text-align: left;
        margin-top: 35px;
        color: #444444;
    }
</style>