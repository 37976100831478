<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">绑定微信</span>
        </div>

        <div class="main" v-show="loadFinished">
            <div v-if="isBindWx" style="text-align: left;color: #464c5c;font-size: 12px;">

                <div class="red-tips-views" style="display: flex;flex-direction: column">
                    <span>解绑微信将无法继续领取福利红包，请谨慎解绑。为了帐号安全，如果帐号绑定了手机号码，还需提供手机验证码</span>
                </div>

                <div style="display: flex;align-items: center;margin-top: 15px;">
                    <img :src="headImg" style="width: 50px;height: 50px;border-radius: 3px;">
                    <span style="font-size: 16px;font-weight: bold; color: #444444;margin-left: 20px;background-color: rgba(1,3,12,0.05);padding: 4px 15px;border-radius: 5px;">{{code}}</span>
                </div>

                <div style="margin-top: 15px" v-if="!showCodeForUnbindWx">
                    <span>已绑定当前微信，如需解绑，请点击下方按钮</span>

                    <van-button type="info" block native-type="submit" :loading="showLoading"
                                style="height: 35px;font-weight: bold;width: 120px;margin-top: 20px;"
                                loading-text="提交中"  @click="unbindWx"
                                color="#EE3F14">
                        解绑微信
                    </van-button>
                </div>

                <div v-else style="margin-top: 20px;color: #888888">
                    <span>本次操作需要身份认证，验证码已发送至您的手机{{bindPhone}}</span>
                    <div style="display: flex;align-items: center;justify-content: flex-start; margin-top: 20px">
                        <div class="input-view" style="margin-top: 0;width: 130px;">
                            <van-field type="digit" maxlength="6" placeholder="请输入验证码" class="common-input" v-model="unbindCode" />
                        </div>

                        <van-button type="info" block native-type="submit" :loading="showLoading"
                                    style="height: 35px;font-weight: bold;width: 110px;margin-left: 15px;"
                                    loading-text="提交中"  @click="unbindWxWithCode"
                                    color="#EE3F14">
                            确认解绑
                        </van-button>
                    </div>
                </div>
            </div>

            <div v-else style="text-align: left;color: #464c5c;font-size: 13px;">
                <div class="green-tips-views" style="display: flex;flex-direction: column">
                    <span>绑定微信才可以领取福利红包，微信绑定流程:</span>
                    <span>1. 关注公众号：七里网络，在公众号聊天框底部菜单：活动福利 - 绑定账号</span>
                    <span>2. 在页面的输入框填写复制好的绑定码，点击绑定按钮</span>
                </div>
                <div style="margin-top: 20px;">
                    <span class="bind-text">绑定码</span>
                    <span style="font-size: 16px;font-weight: bold; color: #444444;margin-left: 20px;background-color: rgba(1,3,12,0.05);padding: 4px 15px;border-radius: 5px;">
                        {{code}}
                    </span>
                </div>
                <div style="margin-top: 30px;">
                    <span>绑定码已复制到剪贴板，去微信绑定可直接粘贴使用</span>
                </div>

                <van-button type="info" block native-type="submit" :loading="showLoading"
                            style="height: 35px;font-weight: bold;width: 100%;margin-top: 20px;"
                            loading-text="正在拉起微信"  @click="openWx"
                            color="#0f78e8">
                    去微信绑定
                </van-button>
            </div>

        </div>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import Toast from "vant/lib/toast";
    import {Dialog} from "vant";
    import notice from '@/api/notice'
    import '../../css/commonpage.css'

    export default {
        name: "shj-weixin",
        components: {
        },
        data() {
            return {
                showLoading: false,
                isBindWx: false,
                code: '',
                headImg: '',
                from: '',
                loadFinished: false,
                unbindWxSendTime: 0,
                showCodeForUnbindWx: false,
                bindPhone: '',
                unbindCode: '',
            }
        },
        mounted () {
            this.from = localStorage.getItem('from');
            this.initData();
        },
        methods: {
            initData() {
                this.showLoading = true;

                let options = {
                    url:"/shjH5/bindWx",
                    data: {}
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.isBindWx = res.data.data.bindStatus === 2;
                        this.code = res.data.data.bindInfo;
                        this.headImg = res.data.data.headImg;
                        if (!this.isBindWx) {
                            notice.copy(this.code)
                        }

                        this.loadFinished = true;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            back() {
                this.$router.back()
            },
            unbindWx() {
                Dialog.confirm({
                    message: '解绑微信后无法参与各种福利活动，确认要解绑吗?',
                    messageAlign: 'left'
                })
                .then(() => {
                    this.checkHasBindPhone();
                })
                .catch(() => {
                });
            },
            checkHasBindPhone() {
                this.bindPhone = localStorage.getItem('phone');
                if(!!this.bindPhone) {
                    let now = new Date().getTime();
                    let interval = (now - this.unbindWxSendTime) /1000;
                    if (interval < 60) {
                        Toast('操作太频繁，请在' + (60 - interval) + '秒后重试');
                        return ;
                    }

                    this.showLoading = true;
                    let options = {
                        url:"/shjH5/sendSms",
                        data: {
                            type: 6
                        }
                    };
                    request(options).then(res => {
                        this.showLoading = false;
                        if (res.data.success) {
                            this.unbindWxSendTime = now;
                            this.showCodeForUnbindWx = true;
                        }
                        else {
                            Toast(res.data.error.message);
                        }
                    });
                }
                else {
                    this.doUnbindWx();
                }
            },
            unbindWxWithCode() {
                if (!/^\d{6}$/.test(this.unbindCode)) {
                    Toast("请输入6位数字验证码");
                    return ;
                }
                this.doUnbindWx();
            },
            doUnbindWx() {
                this.showLoading = true;
                let options = {
                    url:"/shjH5/unBindWx",
                    data: {
                        checkCode: this.unbindCode
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('微信解绑成功');
                        this.back();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            openWx() {
                this.showLoading = true;
                setTimeout(() => {
                    this.showLoading = false;
                }, 2000);
                let url = 'weixin://';
                notice.startIntent(url)
            }
        }
    }
</script>

<style scoped>

    .bind-text{
        font-size: 13px;
        color: #666666;
    }

    .van-cell {
        padding: 7px 10px;
    }
    /deep/ .van-field__control {
        color: #333333;
    }
</style>