<template>
    <div class="all-view">
        <div class="main-page-title">

            <div style="width: 40px;height: 40px;position: relative">
                <img v-if="headerImg" :src="headerImg" style="width: 40px;height: 40px;border-radius: 6px;">
                <img v-else src="../../img/default_img.png" style="width: 40px;height: 40px;border-radius: 6px;">
            </div>

            <div class="top-info-view">
                <div style="font-weight: bold">{{userName}}</div>
                <div style="font-size: 11px;color: #dddddd;">{{uid}}</div>
            </div>

            <div class="top-info-view">
                <div style="font-weight: bold">七里币</div>
                <div style="font-size: 11px;color: #dddddd;">￥{{qlb}}</div>
            </div>

            <div @click="openX5TestPage" class="top-info-view">
                <div style="font-weight: bold">手机号码</div>
                <div style="font-size: 11px;color: #dddddd;">{{tel || '未绑定'}}</div>
            </div>

            <div @click="close" style="width: 30px;height: 30px;position: absolute;right: 2px;top: 2px;background-color: rgba(0,0,0,0.1);box-sizing: border-box;border-radius: 50%;">
                <img src="../../img/close.png" style="width: 30px;height: 30px;">
            </div>

        </div>

        <div class="main-body">
            <div class="menu-view">
                <van-grid :border="true" :column-num="4">
                    <!--<van-grid-item @click="goPage('loginQlb')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/gift.png" style="width: 24px;height: 24px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">送真充</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('redpack')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/redpack.png" style="width: 32px;height: 32px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">领红包</span>
                    </van-grid-item>-->

<!--                    <van-grid-item @click="goPage('share')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/invite.png" style="width: 28px;height: 28px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">邀请好友</span>
                    </van-grid-item>-->

                  <van-grid-item @click="goPage('jgg')">
                    <div class="menu-img-view">
                      <img src="../../img/menu/draw.png" style="width: 28px;height: 28px;">
                    </div>

                    <span style="font-size: 12px;color: #444444;margin-top: 5px;">幸运抽奖</span>
                  </van-grid-item>

                    <van-grid-item @click="goPage('weixin')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/wechat.png" style="width: 30px;height: 30px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">绑定微信</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('kf')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/kf2.png" style="width: 26px;height: 26px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">联系客服</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('recharge')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/chong.png" style="width: 30px;height: 30px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">充值</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('chat')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/chat.png" style="width: 24px;height: 24px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">聊天</span>
                    </van-grid-item>
                    <!--<van-grid-item @click="goPage('trade')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/trade.png" style="width: 28px;height: 28px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">交易平台</span>
                    </van-grid-item>-->

                    <van-grid-item @click="goPage('tel')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/phone.png" style="width: 28px;height: 26px;">
                        </div>
                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">绑定手机</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('password')">
                        <div class="menu-img-view">
                            <img src="../../img/menu/password2.png" style="width: 26px;height: 26px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">修改密码</span>
                    </van-grid-item>
                    <van-grid-item @click="goPage('app')" v-if="from === 'android'">
                        <div class="menu-img-view">
                            <img src="../../img/menu/qili-logo.png" style="width: 24px;height: 24px;border-radius: 3px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">七里APP</span>
                    </van-grid-item>
                    <van-grid-item @click="close">
                        <div class="menu-img-view">
                            <img src="../../img/menu/ys.png" style="width: 24px;height: 24px;border-radius: 3px;">
                        </div>

                        <span style="font-size: 12px;color: #444444;margin-top: 5px;">返回游戏</span>
                    </van-grid-item>
                </van-grid>



                <div class="bottom-view">
                    <div style="display: flex;align-items: center;flex-direction: column" v-if="false">
                        <img src="../../img/main-round.png" style="width: 16px;height: 16px">
                        <span style="font-size: 10px;">七里网络</span>
                    </div>

                    <div style="display: flex;flex-direction: column;">
                        <span style="font-size: 15px;color: #f14646;text-align: left">
                            <span style="font-family: myFont,serif;">山海经幻想录</span>
                            <!--<span style="font-size: 11px;color: #888888;margin-left: 5px;">{{serverMsg}}</span>-->
                        </span>
                        <div style="text-align: left">
                            <!--升级领红包，首冲免费送-->
                            {{serverMsg}}
                        </div>
                    </div>

                    <div @click="logout" style="width: 80px;;height: 25px;background-color: rgba(236,21,21,0.15);color: #ec2222;display: flex;align-items: center;justify-content: center;border-radius: 3px;font-weight: bold">
                        <img src="../../img/menu/logout.png" style="width: 12px;height: 12px;margin-right: 5px;">
                        <span>退出登录</span>
                    </div>
                </div>

            </div>
        </div>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import {Dialog, Toast} from "vant";
    import myloading from '@/components/myloading'
    import notice from '@/api/notice'
    import '../../css/commonpage.css'

    import Vue from 'vue';
    import { Grid, GridItem } from 'vant';
    Vue.use(Grid);
    Vue.use(GridItem);

    export default {
        name: "shj-main",
        components: {
            myloading
        },
        data() {
          return {
              headerImg: '',
              userName: '',
              uid: '',
              tel: '',
              qlb: '0',
              serverMsg: '',
              showLoading: false,
              welfareCode: '',
              from: '',
              userInfo: {},
              gameJs: '',
              clickTestNum: 0,
          }
        },
        mounted() {
            this.handleUrl();
            this.initPage();
            //this.addInputBlur();
        },
        methods: {
            openX5TestPage() {
                if (this.clickTestNum >= 5) {
                    ///this.$router.push("/X5Page");
                    window.location.href = 'http://debugtbs.qq.com'
                }
                else {
                    this.clickTestNum ++;
                }
            },
            addInputBlur() {
                let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 检测是否为ios
                if(!isIOS) {
                    console.log('不是iOS，取消blur事件');
                    return ;
                }
                let t = document.getElementById('inputCode');
                t.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                t.addEventListener('blur', function(){window.scrollTo(0,0)}, false)
            },
            handleUrl() {
                this.from = this.$route.query.from;
                let token = this.$route.query.token || '';
                let gameId = this.$route.query.gameId || '';
                this.gameJs = this.$route.query.gameJs || '';
                if (!token) {
                    Toast('当前登录状态已失效，请重新登录游戏');
                    return ;
                }
                if (!gameId) {
                    Toast('游戏参数非法');
                    return ;
                }

                let speed = localStorage.getItem('game_speed') || '1';
                localStorage.clear();

                localStorage.setItem('game_speed', speed);

                localStorage.setItem('from', this.from);
                localStorage.setItem('token', token);
                localStorage.setItem('gameId', gameId);
                localStorage.setItem('gameJs', this.gameJs);
            },
            initPage() {
                let options = {
                    url:"/shjH5/getLoginInfo",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.userInfo = res.data.data;
                        this.qlb = parseFloat(this.userInfo.qlBalance);
                        this.userName = this.userInfo.userName;
                        this.uid = this.userInfo.userId;
                        this.headerImg = this.userInfo.headerImg || '';
                        this.serverMsg = this.userInfo.serverMsg;
                        if (this.userInfo.phone) {
                            this.tel = this.userInfo.phone;
                            localStorage.setItem('phone', this.userInfo.phone)
                        }

                        localStorage.setItem('userInfo', this.userInfo)
                        localStorage.setItem('userId', this.uid)
                        localStorage.setItem('headerImg', this.headerImg)
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            getFlByCode() {
                if (!this.welfareCode) {
                    Toast('请输入七里福利码');
                    return;
                }
                this.showLoading = true;
                let options = {
                    url:"/shjH5/welfareGet",
                    data: {
                        welfareCode: this.welfareCode
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.welfareCode = '';
                        Toast('礼包码兑换成功');
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            close() {
                notice.close();
            },
            goPage(tag) {
                switch (tag) {
                    case 'tel':
                        if (!this.tel) {
                            this.$router.push("/shj/tel");
                        }
                        else {
                            this.$router.push("/shj/telUnbind");
                        }
                        break;
                   /* case 'tel':
                        this.$router.push("/shj/tel");break;
                    case 'telUnbind':
                        this.$router.push("/shj/telUnbind");break;*/
                    case 'password':
                        this.$router.push("/shj/password");break;
                    case 'weixin':
                        this.$router.push("/shj/weixin");break;
                    case 'kf':
                        this.$router.push("/shj/kf");break;
                    case 'trade':
                        this.$router.push("/shj/trade");break;
                    case 'recharge':
                        this.$router.push("/shj/recharge");break;
                    case 'chat':
                        this.$router.push("/shj/chat");break;
                    case 'redpack':
                        this.$router.push("/shj/redPack");break;
                    case 'loginQlb':
                        this.$router.push("/shj/loginQlb");break;
                    case 'app':
                        this.$router.push("/shj/qiliApp");break;
                    case 'share':
                        this.$router.push("/shj/share");break;
                    case 'jgg':
                      this.$router.push("/shj/jgg");break;

                }
            },
            logout() {
                Dialog.confirm({
                    message: '确认要退出登录吗?',
                }).then(() => {
                    this.exit();
                    localStorage.clear();
                })
                .catch(() => {
                });
            },
            exit() {
                notice.logout();
            },
        }
    }
</script>

<style scoped>
    .all-view {
        width: 100%;
        height: 100vh;
        box-sizing: border-box;
        overflow: hidden;
        max-width: 400px;
        margin: 0 auto;
        background-color: #f8f8f8;
        border-radius: 10px;
    }
    .main-page-title {
        position: relative;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: flex-start;
        background-color: #f14d4d;
    }
    .top-info-view {
        height: 50px;
        padding: 0 10px;
        margin-left: 10px;
        font-size: 12px;
        color: #ffffff;
        background-color: rgba(0,0,0,.1);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .main-body{
        display: flex;
        flex-direction: column;
        padding: 10px 10px 5px;
        box-sizing: border-box;
        position: relative;
        height: calc(100vh - 60px);
    }
    .menu-view {
        background-color: #ffffff;
        border-radius: 5px;
        max-height: calc(100vh - 115px);
        overflow: hidden;
        overflow-y: auto;
    }


    .bottom-view {
        width: calc(100vw - 20px);
        max-width: 380px;
        background-color: #ffffff;
        color: #888888;
        margin-top: 5px;
        border-radius: 5px;
        font-size: 10px;
        padding: 0 10px;
        box-sizing: border-box;
        height: 35px;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 5px;
        justify-content: space-between;
    }
    .menu-img-view {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }












    .left-view {
        height: 230px;
        width: 32%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 10px;
        position: relative;
    }
    .user-base-text {
        font-size: 12px;
        color: #666666;
        display: block;
    }
    .left-text {
        font-size: 10px;
        background: linear-gradient(0deg, #2bba06 4.248046875%, #03b134 49.0478515625%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .left-button {
        padding: 4px 14px;
        border-radius: 3px;
        font-size: 12px;
        color: #f3f3f3;
        background-color: #0263CC;
        user-select: none;
    }
    .exit-button {
        background-color: #EE3F14;
    }
    .right-view {
        flex: 1;
        position: relative;
        height: 230px;
    }
    .main-menu {
        padding: 5px;
        box-sizing: border-box;
        width: 60px;
        height: 50px;
        background-color: #F6A956;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        font-family: myFont,serif;
        color: #C32A1F;
    }
    .second-button {
        width: 100px;
        height: 35px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-family: myFont,serif;
        background-color: #ea7815;
        color: #ffffff;
    }
    .exchange-button {
        width: 60px;
        height: 26px;
        line-height: 26px;
        border: 2px solid #1558dc;
        color: #1558dc;
        font-family: myFont,serif;
        border-radius: 3px;

    }


    .back-input{
        flex: 1;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .back-input .van-cell {
        padding: 1px 10px;
    }
    /deep/.back-input .van-field__control {
        color: #111111;
    }

    /deep/.van-grid-item__content {
        background-color: unset;
        padding: 12px 8px;
    }
</style>