var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share-back"},[_c('img',{staticStyle:{"width":"30px","position":"absolute","left":"12px","top":"12px","z-index":"99"},attrs:{"src":require("../../img/shj/back.png")},on:{"click":_vm.back}}),_c('div',{staticClass:"share-main"},[_vm._m(0),_c('div',{staticClass:"share-center"},[_vm._m(1),_vm._m(2),(_vm.inviteList.length === 0)?_c('div',{staticStyle:{"display":"flex","margin-top":"10px"}},[_vm._m(3),_vm._m(4)]):_vm._e(),_vm._l((_vm.inviteList),function(item){return _c('div',{staticStyle:{"display":"flex","margin-top":"10px"}},[_c('div',{staticClass:"item-left",staticStyle:{"padding":"8px 0 8px 10px","box-sizing":"border-box"}},[_c('span',[_vm._v(_vm._s(item.nickName))]),_c('span',[_vm._v(_vm._s(item.roleInfo))]),_c('img',{staticStyle:{"height":"18px"},attrs:{"src":require("../../img/shj/redpack-name.png")}})]),_c('div',{staticClass:"item-right",class:item.status === '2' && 'item-right-able'},[(item.status === '1')?_c('span',[_vm._v("未完成")]):(item.status === '2')?_c('span',{on:{"click":function($event){return _vm.submit(item.shareInfoId)}}},[_vm._v("领取")]):(item.status === '3')?_c('span',[_vm._v("待审核")]):(item.status === '4')?_c('span',[_vm._v("已发放")]):_vm._e()])])}),(_vm.inviteList.length === 0)?_c('img',{staticStyle:{"height":"40px","margin-top":"20px"},attrs:{"src":require("../../img/shj/invite-friend.png")},on:{"click":_vm.copyLink}}):_c('img',{staticStyle:{"height":"40px","margin-top":"20px"},attrs:{"src":require("../../img/shj/continue-button.png")},on:{"click":_vm.copyLink}}),_c('div',{staticStyle:{"color":"#333333","font-size":"14px","text-align":"left","font-weight":"bold","margin-top":"15px"}},[_vm._v(" 山海经幻想录邀新活动开启啦！！！ ")]),_vm._m(5)],2)]),_c('myloading',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoading),expression:"showLoading"}]})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share-top"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../img/shj/bg-top.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[_c('img',{staticStyle:{"height":"22px"},attrs:{"src":require("../../img/shj/award.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"color":"#888888","font-size":"12px","text-align":"left","margin-top":"5px"}},[_vm._v(" 被邀请的小伙伴完成任务后即可领取奖励"),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-left"},[_c('span',[_vm._v(" 每邀请一位好友并完成任务可领 ")]),_c('img',{staticStyle:{"height":"18px"},attrs:{"src":require("../../img/shj/redpack-name.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-right"},[_c('span',[_vm._v("领取")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"color":"#888888","font-size":"12px","text-align":"left","margin-top":"10px"}},[_vm._v(" 每个被邀请的小伙伴完成任务即能领取5元微信红包，多邀多得，上不封顶"),_c('br'),_vm._v(" 每个被邀请的微信号/设备/网络只能参与一次该活动"),_c('br'),_vm._v(" 该活动只能发送给"),_c('span',{staticStyle:{"color":"#f12727"}},[_vm._v("微信好友")]),_vm._v("才能生效"),_c('br'),_c('br'),_vm._v(" 小伙伴任务要求："),_c('br'),_vm._v(" 1、从您分享的链接下载游戏 "),_c('br'),_vm._v("2、游戏内绑定微信 "),_c('br'),_vm._v("3、游戏等级达到20级"),_c('br'),_c('br'),_vm._v(" 如有任何疑问请联系七里网络客服"),_c('br'),_vm._v(" 最终解释权归七里网络所有"),_c('br')])
}]

export { render, staticRenderFns }