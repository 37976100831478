<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">X5内核</span>
        </div>

        <div class="main">
            <iframe v-show="true" allowfullscreen ref="gameIframe" :src="pageUrl" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
        </div>

    </div>
</template>

<script>
    import Toast from "vant/lib/toast";
    import notice from '@/api/notice'

    export default {
        name: "X5-page-test",
        components: {
        },
        data() {
            return {
                showLoading: false,
                pageUrl: 'http://soft.imtt.qq.com/browser/tes/feedback.html',
            }
        },
        mounted() {
        },
        methods: {
            back() {
                this.$router.back()
            },
        }
    }
</script>

<style scoped>

    .list-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        width: 100%;
        height: 70px;
        padding: 0;
        box-sizing: border-box;
        font-size: 13px;
        background: #ffffff;
        border-radius: 5px;
    }
    .kf-info {
        font-size: 15px;
        color: #333333;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 15px;
    }
    .button-view {
        width: 100%;
        font-size: 12px;
        text-align: left;
        margin-top: 35px;
        color: #444444;
    }
</style>