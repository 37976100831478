import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css';

import {
  Button,
  Icon,
  Image as VanImage,
  Divider,
  Tag,
  List,
  Cell,
  CellGroup,
  Dialog,
  Toast,
  Picker,
  Popup,
  Field,
  Form,
  CountDown,
  Loading,
  Checkbox,
  RadioGroup,
  Radio,
  Overlay,
  Empty,
  NoticeBar,
  Popover,
  Pagination,
  PullRefresh
} from 'vant';

Vue.use(Button)
Vue.use(Icon)
Vue.use(VanImage)
Vue.use(Divider);
Vue.use(Tag);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Form);
Vue.use(CountDown);
Vue.use(Loading);
Vue.use(Checkbox);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Overlay);
Vue.use(Empty);
Vue.use(NoticeBar);
Vue.use(Popover);
Vue.use(Pagination);
Vue.use(PullRefresh)

Vue.config.productionTip = false

router.beforeEach(( to, from, next ) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

router.afterEach(() => {
  // NProgress.done()
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
