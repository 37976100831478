<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">等级红包</span>
        </div>

        <div class="main" style="padding: 0 5px;background: transparent;overflow-y: auto">
            <div style="width: 100%; background-color: #ffffff; display: flex;align-items: center;justify-content: flex-start;padding: 5px 15px; box-sizing: border-box;border-radius: 5px;">
                <img v-if="headerImg" :src="headerImg" style="width: 40px;height: 40px;border-radius: 6px;">
                <img v-else src="../../img/default_img.png" style="width: 40px;height: 40px;border-radius: 6px;">
                <div style="display: flex;flex-direction: column;align-items: flex-start;margin-left: 10px;">
                    <div>
                        <span style="font-size: 13px;color: #333333">{{redRoleInfo.roleName}}</span>
                        <span style="font-size: 11px;color: #888888;margin-left: 5px;">(已创建{{redRoleInfo.roleTime}}天)</span>
                    </div>
                    <span style="font-size: 11px;color: #666666">{{redRoleInfo.roleLevel}}级 {{redRoleInfo.severName}}</span>
                </div>
            </div>

            <div class="item-body">
                <div @click="goCashOutPage" style="width: 100%;display: flex;align-items: center;justify-content: space-between;font-size: 11px;color: #888888;height: 40px;background-color: rgba(239,49,49,0.07);padding: 0 10px;box-sizing: border-box">
                    <span style="color: #444444">
                        红包余额:
                        <span style="font-size: 18px;color: #e75858;font-weight: bold">
                            {{redRoleInfo.redBalance || '0'}}
                        </span>
                        元
                    </span>
                    <span>
                        去提现
                        <van-icon name="arrow" />
                    </span>
                </div>

                <div class="item" v-for="item in itemList">
                    <div>
                        <img src="../../img/menu/redpack.png" style="width: 50px;height: 50px;">
                    </div>
                    <div style="flex: 1; display:flex;flex-direction: column;align-items: flex-start;justify-content: center; font-size: 12px;margin-right: 5px;">
                        <span style="color: #333333;font-size: 12px;font-weight: bold;text-align: left">{{item.redName}}</span>
                        <span style="color: #808080;text-align: left;font-size: 11px;">{{item.redDes}}</span>
                    </div>

                    <div  v-if="item.status === 3" style="width: 60px;height: 25px;text-align: center;line-height: 22px;border-radius: 25px;background-color: #f0f0f0;color: #888888">
                        <span style="font-size: 12px;">已领取</span>
                    </div>
                    <div  v-if="item.status === 1" style="width: 60px;height: 25px;text-align: center;line-height: 22px;border-radius: 25px;background-color: #ffffff;color: #808080;border: 1px solid #888888">
                        <span style="font-size: 11px;">剩余{{item.leaveDays}}天</span>
                    </div>

                    <van-button v-if="item.status === 2" type="info"  native-type="submit" :loading="showLoading" loading-text=""
                                style="height: 25px;width: 60px;font-size: 12px;" round
                                @click="getRed(item.redId)"
                                color="#f54343">
                        <span style="font-size: 12px;">领取</span>
                    </van-button>
                </div>
                <div v-if="itemList.length === 0" style="color: #888888;font-size: 13px;margin: 30px 15px;padding: 10px;background-color: #eeeeee;border-radius: 5px;text-align: left">
                    当前区服该活动已结束，请前往最新区服参与该活动
                </div>


                <img src="../../img/line.png" style="width: 180px;height: 10px;">
                <div style="font-size: 14px;color: #333333;font-weight: bold;margin-bottom: 10px;">活动说明</div>
                <div style="font-size: 11px;color: #333333;text-align: left;display: flex;flex-direction: column;padding: 0 15px 10px;">
                    <span>1、在开启了红包活动的游戏区服内的新用户创建角色后即有资格参加红包活动。</span>
                    <span>2、活动时间60天，从创建角色后即开始倒计时，60天倒计时结束则活动过期，活动过期后红包无法再领取。</span>
                    <span>3、活动过期后，等级红包余额将会清零。</span>
                    <span>4、活动奖项条件中要求的创角时间在等级红包中为游戏中角色创建时间。</span>
                    <span>5、等级红包每个奖项每个账号(无论创建了多少个角色)只可领取1次。</span>
                    <span>6、等级红包余额需满足提现档位(1000)可提现。</span>
                    <span>7、红包提现前请确认帐号已绑定好您的微信号，系统会在15个工作日审核通过后自动将提现金额转账至你绑定的微信账号。请你确保你绑定微信账号的实名信息与游戏的实名信息一致、你的微信支付已开通、你的微信支付不会被微信的风控系统拦截转账，否则可能会导致转账失败。</span>
                    <span>8、实物奖品请在领取后在活动时间内及时联系七里客服领取。</span>
                    <span>9、活动过程中，凡以不正当手段(包括但不限于:侵犯第三人合法权利、作弊、违规账号、恶意刷奖、虚假交易、扰乱系统、网络攻击等)参与活动的用户，将被永久取消活动资格。</span>
                    <span>10、活动过程中有任何疑问，可直接点击主页菜单中的“联系客服”按钮咨询反馈。</span>
                    <span>11、本公司在法律允许范围内负责对本活动进行解释。</span>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {request} from '@/api/request'
    import Toast from "vant/lib/toast";
    import '../../css/commonpage.css'

    export default {
        name: "shj-red-pack",
        components: {
        },
        data() {
            return {
                showLoading: false,
                redRoleInfo: {},
                itemList: [],
                gameId: '',
                headerImg: '',
            }
        },
        mounted () {
            this.headerImg = localStorage.getItem('headerImg');
            this.gameId = localStorage.getItem('gameId');
            this.initData();
            this.initRoleInfo();
        },
        methods: {
            initData() {
                let options = {
                    url:"/shjH5/redInfo",
                    data: {
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        this.itemList = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            initRoleInfo() {
                let options = {
                    url:"/shjH5/redRoleInfo",
                    data: {
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        this.redRoleInfo = res.data.data;
                    }
                });
            },
            getRed(id) {
                let options = {
                    url:"/shjH5/getRed",
                    data: {
                        redId: id
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        Toast('领取成功');
                        this.initData();
                        this.initRoleInfo();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            goCashOutPage() {
                this.$router.push("/shj/cashOut")
            },
            back() {
                this.$router.back()
            },
        }
    }
</script>

<style scoped>


    .item-body{
        margin-top: 5px;
        width: 100%;
        padding: 2px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 5px;
    }
    .item{
        border-radius: 3px;
        min-height: 70px;
        padding: 10px 10px 10px 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

</style>