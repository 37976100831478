import axios from './axios'

const gameId = localStorage.getItem("gameId")
const from = localStorage.getItem("from")
const pathGameId = location.hash.split('?')[1].split('&')[0].split('=')[1];

export async function request (options) {
  let _gameId = '';
  if(from === 'h5'){
    _gameId = pathGameId;
  }else {
    _gameId = gameId;
    if (!_gameId) {
      _gameId = localStorage.getItem("gameId");
    }
  }
  if (options.data) {
    options.data.commonParam = {
      device: '',
      systemVersion: '',
      udid: '',
      channelId: '',
      gameId: _gameId,
      sdkVersion: '',
      time: new Date().getTime()
    };
  }

  return axios({
    url: options.url,
    method: options.method || 'post',
    data: options.data
  })
}

