<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
          <span class="common-title-text">{{ contentDetailData.contentName }}</span>
        </div>

        <div class="main">
            <div class="content__container">
                <div>
                    <div v-html="contentDetailData.contentText"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {request} from "@/api/request";

export default {
    name: "contentView",
    data(){
        return {
            contentId: '',
            contentType: '1',
            contentDetailData: {},
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                this.contentId = to.params.contentId;
                this.contentType = to.params.contentType;
                this.loadContentDetail();
            },
        }
    },
    methods: {
        back() {
          this.$router.back();
        },
        loadContentDetail(){
            let options = {
                url: `/content/getContentDetail`,
                data: {
                    contentId: this.contentId
                }
            }
            request(options).then(res => {
                if(res.data.success){
                    this.contentDetailData = res.data.data;
                }
            })
        }
    }
}
</script>

<style scoped>
.content__container::-webkit-scrollbar {
    display: none;
}
.content__container {
    width: 100%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    
    overflow: hidden;
    overflow-y: auto;
}
.content__title {
    font-weight: 700;
    font-size: 18px;
}
</style>