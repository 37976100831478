<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">提现记录</span>
        </div>


        <div class="main">
            <div class="item-body">
                <div class="green-tips-views">
                    <span>现金红包提现后请等待系统审核，实物礼品领取后请在活动期内联系客服领取</span>
                </div>

                <div class="item" v-for="item in dataList">
                    <div style="display:flex;flex-direction: column;align-items: flex-start;justify-content: center; font-size: 12px;flex: 1;">
                        <span style="color: #333333;font-size: 13px;margin-left: 10px;margin-bottom: 5px;font-weight: bold;text-align: left">{{item.cashOutDes}}</span>
                        <div>
                            <span style="margin-left: 10px;color: #808080">{{item.time}}</span>
                        </div>
                    </div>

                    <div style="width: 80px;text-align: right;padding-right: 10px;">
                        <span v-if="item.status === 1" style="font-size: 12px;color: #ef5f5f">待审核</span>
                        <span v-if="item.status === 2" style="font-size: 12px;color: #23992e">待发放</span>
                        <span v-if="item.status === 3" style="font-size: 12px;color: #333333">已发放</span>
                        <span v-if="item.status === 4" style="font-size: 12px;color: #888888">作废</span>
                    </div>
                </div>

                <div v-if="dataList.length === 0" style="color: #999999;font-size: 15px;margin-top: 20px;">
                    暂无提现记录
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {request} from '@/api/request'
    import Toast from "vant/lib/toast";
    import '../../css/commonpage.css'

    export default {
        name: "shj-login-qlb",
        components: {
        },
        data() {
            return {
                showLoading: false,
                dataList: []
            }
        },
        mounted () {
            this.initData();
        },
        methods: {
            initData() {
                let options = {
                    url:"/shjH5/redCashOutLog",
                    data: {}
                };

                request(options).then(res => {
                    if (res.data.success) {
                        this.dataList = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            back() {
                this.$router.back()
            },
        }
    }
</script>

<style scoped>
    .day-view {
        color: #666666;
        position: absolute;
        font-size: 12px;
        right: 25px;
        top: 15px;
    }

    .item-body{
        width: 100%;
        height: calc(100vh - 70px);
        overflow-y: auto;
    }
    .item{
        background-color: #ffffff;
        height: 60px;
        margin: 5px auto;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px solid #e0e0e0;
    }

</style>