<template>
    <div class="share-back">
        <!--<img src="../../img/shj/invite-bg.png" style="width: 100%;position: absolute;left: 0;z-index: 0">-->

        <img @click="back" src="../../img/shj/back.png" style="width: 30px;position: absolute;left: 12px;top: 12px;z-index: 99">
        <div class="share-main">
            <div class="share-top">
                <img src="../../img/shj/bg-top.png" style="width: 100%;">
            </div>
            <div class="share-center">
                <div style="width: 100%;">
                    <img src="../../img/shj/award.png" style="height: 22px;">
                </div>
                <div style="color: #888888;font-size: 12px;text-align: left;margin-top: 5px;">
                    被邀请的小伙伴完成任务后即可领取奖励<br>
                </div>

                <div v-if="inviteList.length === 0" style="display: flex;margin-top: 10px;">
                    <div class="item-left">
                        <span>
                            每邀请一位好友并完成任务可领
                        </span>
                        <img src="../../img/shj/redpack-name.png" style="height: 18px;">

                    </div>
                    <div class="item-right">
                        <span>领取</span>
                    </div>
                </div>

                <div v-for="item in inviteList" style="display: flex;margin-top: 10px;">
                    <div class="item-left" style="padding: 8px 0 8px 10px;box-sizing: border-box">
                        <span>{{item.nickName}}</span>
                        <span>{{item.roleInfo}}</span>
                        <img src="../../img/shj/redpack-name.png" style="height: 18px;">

                    </div>
                    <div class="item-right" :class="item.status === '2' && 'item-right-able'">
                        <span v-if="item.status === '1'">未完成</span>
                        <span v-else-if="item.status === '2'" @click="submit(item.shareInfoId)">领取</span>
                        <span v-else-if="item.status === '3'">待审核</span>
                        <span v-else-if="item.status === '4'">已发放</span>
                    </div>
                </div>

                <img @click="copyLink" v-if="inviteList.length === 0" src="../../img/shj/invite-friend.png" style="height: 40px;margin-top: 20px;">
                <img @click="copyLink" v-else src="../../img/shj/continue-button.png" style="height: 40px;margin-top: 20px;">

                <div style="color: #333333;font-size: 14px;text-align: left;font-weight: bold;margin-top: 15px;">
                    山海经幻想录邀新活动开启啦！！！
                </div>
                <div style="color: #888888;font-size: 12px;text-align: left;margin-top: 10px;">
                    每个被邀请的小伙伴完成任务即能领取5元微信红包，多邀多得，上不封顶<br>
                    每个被邀请的微信号/设备/网络只能参与一次该活动<br>
                    该活动只能发送给<span style="color: #f12727">微信好友</span>才能生效<br><br>
                    小伙伴任务要求：<br>
                    1、从您分享的链接下载游戏  <br>2、游戏内绑定微信 <br>3、游戏等级达到20级<br><br>
                    如有任何疑问请联系七里网络客服<br>

                    最终解释权归七里网络所有<br>
                </div>




            </div>
        </div>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import {Toast, Dialog} from "vant";
    import notice from '@/api/notice';
    import Myloading from "@/components/myloading.vue";
    import {request} from "@/api/request";


    export default {
        name: "shj-share",
        components: {
            Myloading
        },
        data() {
            return {
                showLoading: false,
                from: '',
                inviteUrl: '',
                inviteList: [],
            }
        },
        mounted() {
            this.initData();
            this.initInviteUrl();
        },
        methods: {
            copyLink() {
                notice.copy(this.inviteUrl);
                Dialog.confirm({
                    title: '邀请好友领大礼',
                    message: '邀请链接已复制到剪切板，马上去微信发送给小伙伴吧。',
                    confirmButtonText: '前往微信',
                    messageAlign: 'left',
                }).then(() => {
                    let url = 'weixin://';
                    notice.startIntent(url);
                }).catch(() => {
                });
            },
            submit(id) {
                this.showLoading = true;
                let options = {
                    url:"/shjH5/getAward?infoId=" + id,
                    method: 'get',
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('红包领取成功，请等待系统审核');
                        this.initData();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            initData() {
                this.showLoading = true;
                let options = {
                    url:"/shjH5/listInfoList",
                    method: 'get',
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.inviteList = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            initInviteUrl() {
                let options = {
                    url:"/shjH5/getShareInfo",
                    method: 'get',
                    data: {
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        this.inviteUrl = res.data.data.shareUrl;
                    }
                    else {
                        //Toast(res.data.error.message);
                    }
                });
            },
            back() {
                this.$router.back()
            },
        }
    }
</script>

<style scoped>
    .share-back {
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        border-radius: 10px;
    }
    .share-top {
        font-size: 0;
        position: absolute;
        top: 0;
        z-index: -9;
    }

    .share-main {
        width: 100%;
        background-image: url(../../img/shj/bg-center.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        overflow-y: auto;
        position: relative;
        z-index: 0;
    }
    .share-center {
        width: 90%;
        background-color: white;
        border-radius: 8px;
        margin: 200px auto 20px;
        padding: 10px 20px;
        box-sizing: border-box;
    }
    .item-left {
        background-image: url(../../img/shj/award-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 70px;
        flex: 1;
        display: flex;
        flex-direction: column;
        font-size: 11px;
        color: #888888;
        align-items: flex-start;
        justify-content: space-evenly;
        padding-left: 10px;
    }
    .item-right {
        background-image: url(../../img/shj/unavailable-get.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 70px;
        width: 70px;
        color: #ffffff;
        font-size: 16px;
        line-height: 70px;
        font-weight: bold;
    }
    .item-right-able {
        background-image: url(../../img/shj/available-get.png);
    }
</style>