<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">送真充</span>
        </div>


        <div class="main">
            <div class="item-body">
                <div class="green-tips-views">
                    <span>登录送充值，最多可领30元，每个微信号/帐号只能参与一次，绑定微信才能参与活动</span>
                </div>

                <div style="padding-left: 5px; margin: 15px auto 0; color: #444444;font-size: 12px;display: flex;border-bottom: 0.5px solid #e0e0e0;padding-bottom: 15px;">
                    <div>
                        已累计登录:
                        {{welfareData.loginDays || '0'}}
                        天
                    </div>
                    <div style="display: flex;align-items: center">
                        <img src="../../img/menu/wechat.png" style="width: 18px;height: 18px;margin-left: 30px;">
                        <span style="margin-left: 5px;color: #666666">{{welfareData.bindWxStatus === 2 ? '已绑定' : '未绑定'}}</span>
                    </div>
                </div>

                <div class="item" v-for="item in itemList">
                    <div style="display:flex;flex-direction: column;align-items: flex-start;justify-content: center; font-size: 12px;">
                        <span style="color: #333333;font-size: 13px;margin-left: 10px;margin-bottom: 5px;font-weight: bold">{{item.name}}</span>
                        <div>
                            <span style="margin-left: 10px;color: #808080">{{item.desc}}</span>
                        </div>
                    </div>

                    <div  v-if="item.status === 3" style="width: 60px;height: 25px;text-align: center;line-height: 22px;border-radius: 25px;background-color: #f0f0f0;color: #888888">
                        <span style="font-size: 12px;">已领取</span>
                    </div>
                    <div  v-if="item.status === 1" style="width: 60px;height: 25px;text-align: center;line-height: 22px;border-radius: 25px;background-color: #f0f0f0;color: #888888">
                        <span style="font-size: 12px;">未达成</span>
                    </div>

                    <van-button v-if="item.status === 2" type="info"  native-type="submit" :loading="showLoading" loading-text=""
                                style="height: 25px;width: 60px;font-size: 12px;" round
                                @click="getWelfare(item.type)"
                                color="#f54343">
                        <span style="font-size: 12px;">领取</span>
                    </van-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {request} from '@/api/request'
    import Toast from "vant/lib/toast";
    import '../../css/commonpage.css'

    export default {
        name: "shj-login-qlb",
        components: {
        },
        data() {
            return {
                showLoading: false,
                welfareData: {},
                itemList: [
                    {name: '1七里币', type: 1, desc: '累计登录1天可领取', status: 1},
                    {name: '5七里币', type: 3, desc: '累计登录3天可领取', status: 1},
                    {name: '6七里币', type: 10, desc: '累计登录10天可领取', status: 1},
                    {name: '6七里币', type: 20, desc: '累计登录20天可领取', status: 1},
                    {name: '6七里币', type: 30, desc: '累计登录30天可领取', status: 1},
                    {name: '6七里币', type: 50, desc: '累计登录50天可领取', status: 1},
                ]
            }
        },
        mounted () {
            this.initData();
        },
        methods: {
            initData() {
                let options = {
                    url:"/shjH5/welfareInfo",
                    data: {}
                };

                request(options).then(res => {
                    if (res.data.success) {
                        this.welfareData = res.data.data;
                        this.itemList[0].status = this.welfareData.status1;
                        this.itemList[1].status = this.welfareData.status3;
                        this.itemList[2].status = this.welfareData.status10;
                        this.itemList[3].status = this.welfareData.status20;
                        this.itemList[4].status = this.welfareData.status30;
                        this.itemList[5].status = this.welfareData.status50;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            back() {
                this.$router.back()
            },
            openBindWx() {
                this.$router.push("bindWx")
            },
            getWelfare(t) {
                this.showLoading = true;
                let options = {
                    url:"/shjH5/getWelfare",
                    data: {
                        type : t
                    }
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('领取成功');
                        this.initData();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .item-body{
        width: 100%;
        height: calc(100vh - 70px);
        overflow-y: auto;
    }
    .item{
        background-color: #ffffff;
        height: 60px;
        margin: 5px auto;
        padding-right: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px solid #e0e0e0;
    }

</style>