import { render, staticRenderFns } from "./RedPack.vue?vue&type=template&id=3533de4e&scoped=true"
import script from "./RedPack.vue?vue&type=script&lang=js"
export * from "./RedPack.vue?vue&type=script&lang=js"
import style0 from "./RedPack.vue?vue&type=style&index=0&id=3533de4e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3533de4e",
  null
  
)

export default component.exports