<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">联系客服</span>
        </div>

        <div class="main">

            <div class="green-tips-views">
                <span>点击可直接进入客服咨询界面</span>
            </div>

            <div class="list-qq list-item" v-for="(item,i) in qqList" @click="openQQ(item.linkUrl)">
                <img src="../../img/qq.png" style="width: 35px;height: 35px;border-radius: 10px;">
                <div class="kf-info">
                    <div>
                        <span style="">{{item.linkUrl}}</span>
                        <span style="margin-left: 10px">{{item.name}}</span>
                    </div>
                    <span style="margin-top: 2px;color: #888888;font-size: 12px;font-weight: normal">{{item.workTime}}</span>
                </div>
            </div>

            <div class="list-wx list-item" v-for="(item,i) in wxList" @click="openWx(item.linkUrl)">
                <img src="../../img/wx.png" style="width: 35px;height: 35px;border-radius: 10px;">
                <div class="kf-info">
                    <span style="">七里网络微信客服</span>
                    <span style="margin-left: 20px" v-if="false">{{item.name}}</span>
                    <span style="margin-top: 2px;color: #888888;font-size: 12px;font-weight: normal">{{item.workTime}}</span>
                </div>
            </div>
        </div>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import myloading from '@/components/myloading'
    import Toast from "vant/lib/toast";
    import notice from '@/api/notice'
    import '../../css/commonpage.css'

    export default {
        name: "shj-kf",
        components: {
            myloading
        },
        data() {
            return {
                showLoading: false,
                from: '',
                qqList: [],
                wxList: [],
            }
        },
        mounted() {
            this.from = localStorage.getItem('from');
            this.initData();
        },
        methods: {
            initData() {
                this.showLoading = true;

                let options = {
                    url:"/shjH5/getConfig",
                    data: {}
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        console.log(res.data.data)
                        this.qqList = res.data.data.qqList;
                        this.wxList = res.data.data.wxList;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            back() {
                this.$router.back()
            },
            openQQ(e) {
                let url = 'mqq://im/chat?chat_type=wpa&uin=' + e + '&version=1&src_type=web';
                notice.startIntent(url)
            },
            openWx(url) {
                notice.openUrl(url)
            },

        }
    }
</script>

<style scoped>

    .list-item {
        display: flex;
        align-items: center;
        margin-top: 15px;
        width: 100%;
        height: 70px;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 13px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        box-shadow: 2px 2px 10px 3px rgba(0,0,0,.1);
        border-radius: 5px;
    }
    .kf-info {
        font-size: 13px;
        color: #444444;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 15px;
    }
</style>