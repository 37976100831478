<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">提现</span>
        </div>

        <div class="main" style="padding-bottom: 5px;">
            <div style="overflow-y: auto;">
                <div style="text-align: left;width: 100%;">
                    <div style="color: #444444;font-size: 12px;margin-top: 10px;display: flex;">
                        <div>
                            红包余额:
                            <span style="font-size: 18px;color: #e75858;font-weight: bold;padding: 0 3px;">
                                {{redRoleInfo.redBalance || '0'}}
                            </span>
                            元
                        </div>
                        <div style="display: flex;align-items: center">
                            <img src="../../img/menu/wechat.png" style="width: 18px;height: 18px;margin-left: 30px;">
                            <span style="margin-left: 5px;color: #666666">{{redRoleInfo.bindWxStatus === 2 ? '已绑定' : '未绑定'}}</span>
                        </div>
                    </div>


                    <div @click="selectItem = !selectItem" class="cash-out-item" :class="selectItem && 'active-item'">
                        <span>1000元</span>
                        <img v-if="selectItem" src="../../img/selected_icon.png" style="width: 12px;height: 10px;position: absolute;right: 10px;">
                    </div>
                </div>

                <div style="display: flex;align-items: center;position: relative;justify-content: center;margin-top: 20px;">
                    <van-button  type="info"  native-type="submit" :loading="showLoading" loading-text="提交中"
                                 style="height: 35px;width: 130px;" round
                                 @click="submit"
                                 color="#f54343">
                        <span style="font-size: 14px;">确认提现</span>
                    </van-button>

                    <span @click="goCashOutLogPage" style="color: #999999;position: absolute;right: 0;font-size: 12px;">提现记录</span>
                </div>

                <div style="text-align: left;color: #333333;font-size: 11px;margin-top: 15px;">
                    <div>1、现金提现将会在15个工作日内将现金发放至绑定的微信钱包中。没有实名开通微信支付的用户将无法发放提现现金</div>
                    <div>2、提现申请一经提交，将无法修改</div>
                    <div>3、请在活动时间结束前完成提现，活动时间结束后未提现金额将会全部清0</div>
                    <div>4、根据相关法律法规要求规定，游戏实名和提现微信实名必须一致，否则将导致提现失败</div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {request} from '@/api/request'
    import Toast from "vant/lib/toast";
    import {Dialog} from "vant";

    export default {
        name: "shj-cash-out",
        data() {
            return {
                showLoading: false,
                selectItem: false,
                redRoleInfo: {},
            }
        },
        mounted () {
            this.initData();
        },
        methods: {
            initData() {
                let options = {
                    url:"/shjH5/redRoleInfo",
                    data: {
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        this.redRoleInfo = res.data.data;
                    }
                });
            },
            submit() {
                if (!this.selectItem) {
                    Toast({
                        message: '请选择提现额度',
                        position: 'bottom',
                    });
                    return ;
                }
                Dialog.confirm({
                    message: '确认要申请提现吗?',
                }).then(() => {
                    this.doCashOut('1000');
                })
                .catch(() => {
                });
            },
            doCashOut (amount) {
                let options = {
                    url:"/shjH5/redCashOut",
                    data: {
                        cashOutNumber: amount
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        Toast({
                            message: '提现申请成功，系统审核成功后将会发送到您绑定的微信账号',
                            position: 'bottom',
                        });
                        this.initData();
                        this.selectItem = false;
                    }
                    else {
                        Toast({
                            message: res.data.error.message,
                            position: 'bottom',
                        });
                    }
                });
            },
            goCashOutLogPage() {
                this.$router.push("/shj/cashOutLog")
            },
            back() {
                this.$router.back()
            },
        }
    }
</script>

<style scoped>
    .cash-out-item {
        width: 120px;
        height: 35px;
        border: 1px solid #cccccc;
        border-radius: 3px;
        color: #333333;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        position: relative;
    }
    .active-item {
        border: 1px solid #dc2929;
        color: #dc2929;
    }
</style>