<template>
  <div class="common-body-view">
    <div  class="common-title">
      <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
      <span class="common-title-text">幸运抽奖</span>
    </div>
    <iframe :src="iframeUrl" frameborder="0" scrolling="no" style="width: 100%;height: calc(100vh - 50px);" />
  </div>
</template>

<script>
export default {
  name: "jiugongge",
  data(){
    return {
      iframeUrl: 'https://sdkh5.7li.cn/w'
    }
  },
  mounted() {
    const gameId = localStorage.getItem("gameId");
    const token = localStorage.getItem("token");
    if (gameId && token) {
      this.iframeUrl = 'https://sdkh5.7li.cn/w?gameId=' + gameId + '&token=' + token;
    }
  },
  methods: {
    back(){
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>