<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">解绑手机</span>
        </div>

        <div class="main">
            <div class="red-tips-views">
                <span>如手机号码不再使用，请及时解绑并绑定新手机号码</span>
            </div>

            <div class="input-view">
                <van-field label="手机号码" label-width="70"  maxlength="11"  class="common-input" v-model="tel" readonly />
            </div>

            <div class="input-view">
                <van-field  label="验证码" label-width="70" type="digit" id="inputCode" maxlength="6" class="common-input" v-model="code" placeholder="请输入验证码">
                    <template #button>
                        <van-count-down
                                v-show="showCountDown"
                                ref="countDown"
                                :time="60000"
                                :auto-start="false"
                                format="ss秒重发"
                                style="font-size: 12px;color: #888888"
                                @finish="finish"
                        />
                        <span  style="color: #0f78e8;font-size: 12px;" v-show="!showCountDown" @click="sendCode">发送验证码</span>
                    </template>
                </van-field>
            </div>

            <van-button type="info" block native-type="submit" :loading="showLoading"
                        style="height: 35px;font-weight: bold;width: 100%;margin-top: 20px;"
                        loading-text="正在提交"  @click="submit"
                        color="#EE3F14">
                立即解绑
            </van-button>
        </div>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import Toast from "vant/lib/toast";

    export default {
        name: "shj-tel-unbind",
        components: {
        },
        data() {
            return {
                showLoading: false,
                tel: '',
                code: '',
                showCountDown: false,
                codeReg : /^\d{6}$/,
            }
        },
        mounted() {
            this.tel = localStorage.getItem('phone');
            this.addInputBlur();
        },
        methods: {
            back() {
                this.$router.back()
            },
            finish() {
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            sendCode() {
                this.showLoading = true;
                let options = {
                    url:"/shjH5/sendSms",
                    data: {
                        type: 3
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.showCountDown = true;
                        this.$refs.countDown.start();
                        Toast('验证码发送成功');
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            submit() {
                if (!this.codeReg.test(this.code)) {
                    Toast('请输入6位数字验证码');
                    return ;
                }

                this.showLoading = true;
                let options = {
                    url:"/shjH5/unBindPhone",
                    data: {
                        checkCode: this.code
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('手机号码解绑成功');
                        localStorage.removeItem('phone');
                        this.tel = '';
                        this.code = '';
                        this.back();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            addInputBlur() {
                let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 检测是否为ios
                if(!isIOS) {
                    console.log('不是iOS，取消blur事件');
                    return ;
                }
                let t = document.getElementById('inputCode');
                t.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                t.addEventListener('blur', function(){window.scrollTo(0,0)}, false)
            },
        }
    }
</script>

<style scoped>
    .van-cell {
        padding: 7px 10px;
    }
    /deep/ .van-field__control {
        color: #333333;
    }
</style>