<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">消费查询</span>
        </div>
         
        <div class="main">
            <div class="record-info-item record-info-item-title">
                <div>商品名称</div>
                <div>商品价格</div>
                <div>购买时间</div>
            </div>
            <div class="record-info">
                <div v-if="list.length === 0">
                    <img src="../../img/gift/no_item.png" style="width: 120px;height: 90px;">
                    <div class="record-info-item-title" style="color: #C7C8FE;">暂无消费记录</div>
                </div>
                <div v-else>
                    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                        <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            loading-text="拼命加载中..."
                            :offset="150"
                            @load="onLoad">
                            <div class="record-info-item" v-for="(item,index) in list" :key="index">
                                <div>{{ item.orderDesc }}</div>
                                <div>{{ item.orderAmount }}</div>
                                <div>{{ item.orderTime }}</div>
                            </div>
                        </van-list>
                    </van-pull-refresh>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import {Toast} from "vant";
import {request} from "@/api/request";

export default {
    name: "ExpenseCalendar",
    data(){
        return {
            currentPage: 1,
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
        }
    },
    mounted() {
        this.submit();
    },
    methods: {
        back() {
            this.$router.back()
        },
        submit(){
            if(this.refreshing){
                this.list = [];
                this.refreshing = false;
            }
            
            let options = {
                url: `/sdkH5/userOrder`,
                data: {
                    pageNo: this.currentPage,
                    pageSize: 10,
                }
            }
            request(options).then(res => {
                if(res.data.success){
                    if(res.data.data.length !== 0){
                        this.loading = false;
                        this.list = this.list.concat(res.data.data);
                        this.currentPage++;
                    }
                    //如果加载完毕，显示没有更多
                    if(res.data.data.length === 0) {
                        this.finished = true;
                    }
                }else {
                    Toast(res.data.error.message);
                }
            })
        },
        onLoad() {
            this.submit();
            this.loading = true;
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.list = [];
            this.currentPage = 1;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.submit();
        },
    }
}
</script>

<style scoped>
    .record-info{
        width:100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /*margin: 10px 0;*/
        border-radius: 5px;
        overflow-y: auto;
    }
    .record-info-item-title{
        font-weight: bold;
    }
    .record-info-item{
        width: 100%;
        height: 10%;
        background-color: #fff;
        font-size: 14px;
        display: grid;
        grid-template-columns: repeat(3,33.33%);
        justify-content: space-around;
        align-items: center;
        border-bottom: 1px solid #f2f2f2;
    }
</style>